@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom styles */
body {
  font-family: 'Roboto', sans-serif;
}

.bg-lavender {
  background-color: #e6e6fa;
}

/* Sidebar styles */
.sidebar {
  @apply w-64 bg-gray-800 text-white h-screen overflow-y-auto;
}

.sidebar-header {
  @apply bg-indigo-600 p-4;
}

.sidebar-title {
  @apply text-white no-underline text-2xl font-bold;
}

.nav-list {
  @apply list-none p-0 m-0;
}

.nav-item {
  @apply flex items-center p-3 text-white no-underline transition-colors duration-200;
}

.nav-item:hover, .nav-item.active {
  @apply bg-indigo-700;
}

.nav-icon {
  @apply mr-2;
}

.nav-list-child {
  @apply bg-gray-700;
}

.nav-list-child .nav-item {
  @apply pl-8;
}

.expand-icon {
  @apply ml-auto;
}

/* Main content styles */
.main-content {
  @apply flex-grow p-8 bg-white rounded-3xl shadow-lg;
}

/* Home page styles */
.home-container {
  @apply max-w-7xl mx-auto;
}

.main-title {
  @apply text-4xl text-gray-800 text-center mb-8;
}

.hero-section {
  @apply relative mb-8;
}

.hero-image {
  @apply w-full h-auto rounded-lg;
}

.hero-text {
  @apply absolute bottom-8 left-8 text-white bg-black bg-opacity-70 p-4 rounded-lg;
}

.hero-text h2 {
  @apply text-3xl mb-2;
}

.section-title {
  @apply text-3xl text-gray-800 mb-4;
}

.feature-grid {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8;
}

.feature-card {
  @apply bg-white p-8 rounded-lg shadow-md transition-transform duration-300 hover:-translate-y-1;
}

.feature-icon {
  @apply text-5xl mb-4 text-indigo-600;
}

.vision-section, .graph-section {
  @apply bg-white p-8 rounded-lg shadow-md mb-8;
}

/* Responsive design */
@media (max-width: 768px) {
  .sidebar {
    @apply fixed left-0 top-0 bottom-0 -left-64 transition-all duration-300 ease-in-out z-50;
  }

  .sidebar.open {
    @apply left-0;
  }

  .main-content {
    @apply ml-0;
  }

  .hamburger-menu {
    @apply block fixed top-4 left-4 z-50 bg-transparent border-0 text-gray-800 text-2xl cursor-pointer;
  }

  .hero-text {
    @apply static bg-gray-800 mt-0 rounded-b-lg;
  }
}

/* Run of Show specific styles */
.run-of-show .pre-show { @apply bg-red-100; }
.run-of-show .event { @apply bg-green-100; }
.run-of-show .sponsor { @apply bg-yellow-100; }
.run-of-show .gap { @apply bg-gray-100; }
.run-of-show .concert { @apply bg-blue-100; }

/* Custom scrollbar for sidebar */
.sidebar::-webkit-scrollbar {
  width: 6px;
}

.sidebar::-webkit-scrollbar-track {
  @apply bg-gray-700;
}

.sidebar::-webkit-scrollbar-thumb {
  @apply bg-gray-600 rounded-full;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500;
}
